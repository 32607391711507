import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ProgressBarModule } from 'primeng/progressbar';
import { DialogService } from 'primeng/dynamicdialog';

import { HttpFooterService } from '../../common/httpservices/httpfooter.service';
import { CommonComponentsModule } from '../common/commoncomponents.module';
import { AppLoaderComponent } from './apploader/apploader.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout.component';
import { OffsideBarComponent } from './offsidebar/offsidebar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserInfoComponent } from './sidebar/userinfo/userinfo.component';
import { UserInformationComponent } from './header/userinfo/userinfo.component';
import { RoleSelectorComponent } from './header/userinfo/roleselector/roleselector.component';
import { LanguageSelectorComponent } from './header/userinfo/languageselector/languageselector.component';
import { LoadingBarComponent } from './loading/loading.component';

@NgModule({
    imports: [CommonModule, ProgressBarModule, FormsModule, RouterModule, TranslateModule, CollapseModule, StoreModule, CommonComponentsModule],
    declarations: [
        SidebarComponent,
        UserInfoComponent,
        UserInformationComponent,
        RoleSelectorComponent,
        LanguageSelectorComponent,
        LayoutComponent,
        HeaderComponent,
        LoadingBarComponent,
        OffsideBarComponent,
        AppLoaderComponent,
        FooterComponent,
    ],
     exports: [LayoutComponent, HeaderComponent, SidebarComponent, OffsideBarComponent, AppLoaderComponent, LoadingBarComponent],
    providers: [HttpFooterService, DialogService],
})
export class LayoutModule {}
