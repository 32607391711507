<p-calendar
    placeholder="{{ 'common.datepicker.format' | translate }}"
    [showIcon]="showIcon"
    [readonlyInput]="isTextDisabled"
    [disabled]="isDisabled"
    [minDate]="minDateAsDate"
    [showButtonBar]="showButtonBar"
    [(ngModel)]="value"
    (onBlur)="blur.emit(true)"
    (onClearClick)="onClear.emit(true)"
    (onSelect)="setValue($event)"
    appendTo="body"
    [dateFormat]="dateFormat"
    [firstDayOfWeek]="1"
    [view]="view"
>
    <ng-template pTemplate="triggericon">
        <i class="ph-light {{ icon }}"></i>
    </ng-template>
</p-calendar>
