import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';

import { HttpService } from '../../../../common/core/http/http.service';
import { MappingHttpService } from '../../../../common/core/http/mappinghttp.service';
import { UriBuilder } from '../../../../common/core/http/uri.builder';
import { PricingMiscModel } from './models/pricingmisc.model';







@Injectable()
export class HttpPricingDiscountService {
    private _uri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('pricing/discount');
    protected get baseUri(): UriBuilder {
        return this._uri.clone();
    }

    constructor(private httpService: HttpService,
        private tenantConfigurationFactory: TenantConfigurationFactory,
        private mappingHttpService: MappingHttpService) { }

    public getDiscountArticle(isLongRequest: boolean = true): Observable<PricingMiscModel> {
        return this.mappingHttpService.getAndMap<PricingMiscModel>(
            PricingMiscModel,
            this.baseUri.build(),
            null,
            true
        );
    }
}
