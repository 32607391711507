import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '../../../../common/core/http/http.service';
import { MappingHttpService } from '../../../../common/core/http/mappinghttp.service';
import { UriBuilder } from '../../../../common/core/http/uri.builder';
import { PricingMiscModel } from './models/pricingmisc.model';
import { IPricingMiscFilterDefinition } from './models/pricingmiscfilterdefinition';
import { PricingMiscListPageResult } from './models/pricingmisclist.result';







@Injectable()
export class HttpPricingMiscService {
  private _uri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('pricing/misc');
  protected get baseUri(): UriBuilder {
    return this._uri.clone();
  }

  constructor(private httpService: HttpService,
    private tenantConfigurationFactory: TenantConfigurationFactory,
    private mappingHttpService: MappingHttpService) { }

  public getPaged(filterDefinition: IPricingMiscFilterDefinition, isLongRequest: boolean = true): Observable<PricingMiscListPageResult> {
    return this.httpService.getPagedFiltered<PricingMiscListPageResult, IPricingMiscFilterDefinition>(
      this.baseUri.build(),
      filterDefinition,
      null,
      isLongRequest
    );
  }

  public getByArticleNumber(articleNumber: string): Observable<PricingMiscModel> {
    return this.mappingHttpService.getAndMap<PricingMiscModel>(
      PricingMiscModel,
      this.baseUri
        .withPath('article')
        .withQueryString('articleNumber', articleNumber)
        .build(),
      null,
      true
    );
  }

  public create(model: PricingMiscModel): Observable<boolean> {
    return this.httpService.custom('POST', this.baseUri.build(), model, null, true).pipe(map(resp => resp.status === 204));
  }

  public update(id: number, model: PricingMiscModel): Observable<PricingMiscModel> {
    return this.httpService.update<PricingMiscModel, PricingMiscModel>(model, this.baseUri.withId(id).build(), null, true);
  }

  public delete(id: number) {
    return this.httpService.delete(this.baseUri.withId(id).build(), null, true);
  }
}
