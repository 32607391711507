import { Routes } from '@angular/router';

import { HasPermissionGuard } from './common/core/auth/permission.guard';
import { AuthContainerComponent } from './components/auth/authcontainer.component';
import { CallbackComponent } from './components/auth/callback.component';
import { ForbiddenComponent } from './components/auth/forbidden.component';
import { LoginComponent } from './components/auth/login.component';
import { LogoutComponent } from './components/auth/logout.component';

export const appRouting: Routes = [
    {
        path: 'auth',
        component: AuthContainerComponent,
        children: [
            { path: '', redirectTo: 'login', pathMatch: 'full' },
            {
                path: 'callback',
                component: CallbackComponent,
            },
            {
                path: 'logout',
                component: LogoutComponent,
            },
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'forbidden',
                component: ForbiddenComponent,
            },
        ],
    },
    {
        path: 'policy',
        loadComponent: () => import('./components/policy/policy.component').then((x) => x.PolicyComponent),
    },
    {
        path: 'dashboard',
        data: { breadcrumbkey: 'header.breadcrumb.dashboard' },
        loadChildren: () => import('./components/dashboard/dashboard.module').then((x) => x.DashboardModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('./components/admin/admin.module').then((x) => x.AdminModule),
    },
    {
        path: 'my-profile',
        loadChildren: () => import('./components/myprofile/myprofile.module').then((x) => x.MyProfileModule),
        canActivate: [HasPermissionGuard],
        data: {
            permission: 'Profile.Edit',
            breadcrumbkey: 'header.breadcrumb.myprofile',
        },
    },
    {
        path: 'mycourses',
        loadChildren: () => import('./components/mycourses/mycourses.module').then((x) => x.MyCouresModule),
    },
    {
        path: 'mycertificates',
        loadChildren: () => import('./components/mycertificates/mycertificates.module').then((x) => x.MyCertificatesModule),
    },
    {
        path: 'notifications',
        data: { breadcrumbkey: 'header.breadcrumb.notifications' },
        loadChildren: () => import('./components/notifications/notifications.module').then((x) => x.NotificationsModule),
    },
    {
        path: 'testing',
        loadChildren: () => import('./components/testing/testing.module').then((x) => x.TestingModule),
    },
    {
        path: 'coursecatalog',
        data: { breadcrumbkey: 'header.breadcrumb.coursecatalog.overview' },
        loadChildren: () => import('./components/coursecatalog/coursecatalog.module').then((x) => x.CourseCatalogModule),
    },
    {
        path: 'mysubscriptions',
        loadChildren: () => import('./components/mysubscriptions/mysubscriptions.module').then((x) => x.MySubscriptionsModule),
    },
    {
        path: 'payments',
        loadChildren: () => import('./components/paymentflow/paymentflow.module').then((x) => x.PaymentflowModule),
    },
    {
        path: 'documentoutlines',
        loadChildren: () => import('./components/doco/doco.module').then((x) => x.DocoModule),
    },
    {
        path: 'syllabi',
        loadChildren: () => import('./components/syllabi/syllabi.module').then((x) => x.SyllabiModule),
    },
    {
        path: 'registrations',
        loadChildren: () => import('./components/registrations/registrations.module').then((x) => x.RegistrationsModule),
    },
    {
        path: 'calendar',
        loadChildren: () => import('./components/calendar/calendar.module').then((x) => x.CalendarModule),
    },
    {
        path: 'reporting',
        loadChildren: () => import('./components/reporting/reporting.module').then((x) => x.ReportingModule),
    },
    {
        path: 'invoices',
        data: { breadcrumbkey: 'header.breadcrumb.invoices.overview' },
        loadChildren: () => import('./components/invoices/invoices.module').then((x) => x.InvoicesModule),
    },
    {
        path: 'orders',
        data: { breadcrumbkey: 'header.breadcrumb.orders' },
        loadChildren: () => import('./components/orders/orders.module').then((x) => x.OrdersModule),
    },
    {
        path: 'faq',
        data: { breadcrumbkey: 'header.breadcrumb.faq.overview' },
        loadChildren: () => import('./components/faq/faq.module').then((x) => x.FaqModule),
    },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];
