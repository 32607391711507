<ag-grid-angular
    #agGrid
    id="{{ gridId }}"
    style="width: 100%; height: 100%"
    [class]="themeClass"
    [rowData]="rowData"
    [columnDefs]="agColumnDefinition"
    [defaultColDef]="defaultColDef"
    [domLayout]="domLayout"
    [popupParent]="popupParent"
    (gridReady)="onGridReady($event)"
    (firstDataRendered)="headerHeightSetter()"
    (columnResized)="headerHeightSetter()"
    (sortChanged)="onSortChanged($event)"
    [stopEditingWhenCellsLoseFocus]="true"
></ag-grid-angular>

<pagination
    *ngIf="enablePaging && totalItems > itemsPerPage"
    class="justify-content-center mt-3"
    [totalItems]="totalItems"
    [itemsPerPage]="itemsPerPage"
    [maxSize]="maxNavLinks"
    [(ngModel)]="currentPage"
    (pageChanged)="pageChanged($event)"
    [customPreviousTemplate]="prevTemplate"
    [customNextTemplate]="nextTemplate"
    [customFirstTemplate]="firstTemplate"
    [customLastTemplate]="lastTemplate"
    [boundaryLinks]="enableFirstAndLastPagination"
></pagination>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <ng-container><i class="ph-light ph-caret-circle-double-left"></i></ng-container>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <ng-container><i class="ph-light ph-arrow-circle-left"></i></ng-container>
</ng-template>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <ng-container>
        <i class="ph-light ph-arrow-circle-right"></i>
    </ng-container>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <ng-container>
        <i class="ph-light ph-caret-circle-double-right"></i>
    </ng-container>
</ng-template>
